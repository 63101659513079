import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import PaymentModal from "../components/PaymentModal";

function WhoWeAre() {
  const [showModal, setShowModal] = useState(false);

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleOpenModal() {
    setShowModal(true);
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sunrise Speech and Swallowing Therapy | Who We Are</title>
        <link
          rel="canonical"
          href="https://sunrisespeechandswallowing.com/who-we-are"
        />
        <meta
          name="keywords"
          content="Speech therapy, Swallowing therapy, Asheville, NC"
        />
      </Helmet>
      <main>
        <Nav openModal={handleOpenModal} />
        {showModal && <PaymentModal closeModal={handleCloseModal} />}

        <section className="bg-white border-b py-8 mt-8">
          <div className="container max-w-l mx-auto m-8 text-center">
            <h1 className="w-full my-2 mt-12 text-5xl font-bold leading-tight mb-4 text-center text-sunrise-blue">
              Who We Are
            </h1>
            <div className="flex justify-center">
              <p className="pr-5 pl-5 sm:p-0 max-w-2xl text-center text-xl leading-8">
                {`Sunrise Speech and Swallowing Therapy is dedicated to serving adults in WNC with communication and swallowing disorders. Our team has successfully treated thousands of people across many different diagnoses affecting communication and swallowing. With years of experience in the hospital and outpatient settings, we possess a unique skill set and have worked with many different types of disorders. At the heart of our practice are the individuals we care for. Our main goal is to learn all we can about you and the parts of your life affected by your communication and swallowing needs. Using the most up to date and effective assessment and treatment techniques, our goal is to help improve your quality of life.`}
              </p>
            </div>
          </div>
        </section>

        <Footer openModal={handleOpenModal} />
      </main>
    </>
  );
}

export default WhoWeAre;
